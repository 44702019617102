import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import Layout from "../components/common/Layout/layout";
import SEO from "../components/common/Seo/seo.js";
import ResponsiveTitle from "../components/common/ResponsiveTitle";
import Box from "../components/common/Box";
import ResponsiveText from "../components/common/ResponsiveText";

const Lists = props => {
  const [dragging, setDragging] = useState(false);

  const handleDragStart = (event, info) => {
    setDragging(true);
  };
  const handleDragEnd = (event, info) => {
    console.log("event", event);
    console.log("info", info);
    setDragging(false);
  };

  return (
    <Layout>
      <SEO title="Lists" />
      <Box as="main" id="main" textAlign="center">
        <motion.div
          drag
          dragConstraints={{
            top: -100,
            left: -100,
            right: 100,
            bottom: 100,
          }}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <ResponsiveText min={48} max={86}>
            <span role="img" title="Sad face" aria-label="Sad face">
              {dragging ? "😎" : "😔"}
            </span>
          </ResponsiveText>
        </motion.div>
        <ResponsiveTitle min={32} max={48}>
          No lists here yet.
        </ResponsiveTitle>
      </Box>
    </Layout>
  );
};

Lists.propTypes = {};

export default Lists;
